import React from "react";
import styled from "styled-components";
import { ButtonLinkSolid } from "../../components/button";
import { PimcoreImage } from "../../components/pimcore-image";
import QuoteElement, { QuoteProps } from "../../components/quote-element/quote-element";
import { Headline3, Paragraph } from "../../components/typography";
import { WorkRoutes, WorkRoutesInterface } from "../../data/routing";
import { ImageDataInterface } from "../../graphql/common";
import { useMatchMedia } from "../../hooks/use-match-media";
import { theme } from "../../styles/styles";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";

interface TeamSectionProps {
  name: string;
  description: string;
  mainImage: ImageDataInterface;
  mainImageMobile?: ImageDataInterface;
  gallery?: Array<{ image: ImageDataInterface }>;
  link: keyof WorkRoutesInterface;
  index: number;
}

interface DescriptionProps {
  padding?: string;
}

interface CellProps extends DescriptionProps {
  $inversed?: boolean;
  $modifier?: string;
}

interface WrapperProps {
  margin?: string;
}

interface GalleryProps {
  $align?: "left" | "right";
  $modifier?: string;
}

interface ImageWrapperProps {
  $modifier?: string;
}

const contentModifier: Array<{ modifier: string }> = [
  { modifier: "tablet:pt-16 tablet:pb-32" },
  { modifier: "tablet:py-24" },
  { modifier: "" },
  { modifier: "" },
  { modifier: "" },
  { modifier: "" },
  { modifier: "" },
  { modifier: "tablet:py-32 mb-14 tablet:mb-0" },
];

const paddingDescription: Array<string> = [
  "tablet:pr-16",
  "tablet:pr-14 tablet:mr-1",
  "tablet:pr-14",
  "tablet:pr-11",
  "tablet:pr-10",
  "tablet:pr-8",
  "tablet:pr-8",
  "tablet:pr-14",
];

const quotes: Array<QuoteProps | undefined> = [
  {
    message:
      "In the Digital UX/UI team, we make data-driven decisions by constantly looking into both qualitative and quantitative insights in order to provide the best in class experiences for our users.",
    name: "Eray",
    position: "Senior UX/UI Manager",
    modifier: "left-4 bottom-4 tablet:left-auto tablet:bottom-56 tablet:right-72",
  },
  {
    message: "Data is not just essential for our digitalization strategy, powering operations and growth; it’s necessary for success in today’s hyperconnected world.",
    name: "Ekaterina",
    position: "Consent Manager",
    modifier: "left-4 bottom-4 tablet:left-auto tablet:bottom-40 tablet:right-[31rem]",
  },
  {
    message: (
      <>
        Technology and customer&nbsp;expectations are&nbsp;constantly changing. To meet them we must continuously adapt
        to new&nbsp;trends.
      </>
    ),
    name: "Samin",
    position: "Frontend Architect",
    modifier: "left-4 bottom-4 tablet:left-auto tablet:bottom-44 tablet:right-72",
  },
  undefined,
  {
    message:
      "Our team is the enabler of our mission, by bringing knowledge into one place and helping our colleagues work more effectively.",
    name: "Jinny",
    position: "Head of Knowledge Management",
    modifier: "left-4 bottom-4 tablet:bottom-60 tablet:left-[32rem]",
    left: true,
  },
  {
    message:
      "We make sure we hire, motivate and ensure the safety of the people who can drive the digital future of Miele.",
    name: "Mariya",
    position: "HR Generalist",
    modifier: "left-4 bottom-4 tablet:left-auto tablet:right-[30rem] tablet:-top-4",
  },
  {
    message:
      "We strive to be the mission control centre for Miele X’s cross-functional teams. Enabling them so they can focus on advancing the digital acceleration of Miele.",
    name: "Barbara",
    position: "Director Operational Excellence and Information Management",
    modifier: "left-4 bottom-4 tablet:left-auto tablet:right-[32rem] tablet:bottom-[32rem]",
  },
  {
    message: (
      <>
        We love finding innovative ways to engage with our consumers and bring the&nbsp;Miele brand to life in digital
        channels.
      </>
    ),
    name: "Chiara",
    position: "Digital Campaign Manager",
    modifier: "left-4 bottom-4 tablet:left-auto tablet:bottom-24 tablet:right-[18rem]",
  },
];

const galleryModifiers: Array<{ main: string; bottom?: string }> = [
  { main: "tablet:ml-20 ", bottom: "tablet:mr-20 " },
  {
    main: "tablet:ml-20 tablet:mr-10",
    bottom: "tablet:mr-24 tablet:ml-48 ",
  },
  { main: "tablet:ml-10 tablet:mr-28", bottom: "tablet:mr-0 " },
  { main: "tablet:mr-28", bottom: "tablet:mr-28 tablet:ml-40  " },
  {
    main: "tablet:ml-20 tablet:w-4/5",
    bottom: "tablet:ml-20 tablet:w-4/6 ",
  },
  { main: "tablet:mr-28 tablet:-mt-12 tablet:mb-36 " },
  {
    main: "tablet:ml-28 tablet:mr-28 tablet:-mt-14",
    bottom: "tablet:mr-60 tablet:ml-28 ",
  },
  { main: "tablet:mr-16 tablet:pt-20 tablet:mt-10", bottom: "" },
];

const TeamSection = React.forwardRef<HTMLDivElement, TeamSectionProps>(
  ({ name, description, mainImage, mainImageMobile, gallery, index, link }, ref) => {
    const matchMobile = useMatchMedia("(max-width: 768px)");
    const wrapperRef = React.createRef<HTMLDivElement>();
    const tl = React.useRef<GSAPTimeline>();
    const inversed = index % 2 == 1;

    React.useEffect(() => {
      const endPosition = matchMobile ? "bottom center" : "bottom+=20% bottom";
      tl.current = onScrollAnimationDesktop(
        wrapperRef.current,
        [
          wrapperRef?.current?.children[1].children[1].children[0] || null,
          wrapperRef?.current?.children[1].children[2] || null,
          wrapperRef?.current?.children[0] || null,
          wrapperRef?.current?.children[2].children || null,
          wrapperRef?.current?.children[1].children[3]?.children || null,
        ],
        matchMobile,
        ["8rem", "14rem"],
        endPosition
      );

      return () => {
        tl?.current?.kill();
      };
    }, [matchMobile]);

    return (
      <ScrollToWrapper ref={ref}>
        <Wrapper ref={wrapperRef} margin={index === 7 ? "tablet:mb-6" : ""}>
          <Box $inversed={inversed} />
          <GalleryCell $inversed={index % 2 == 1}>
            {quotes[index] && <QuoteElement {...quotes[index]} place="work" />}
            <MainImageWrapper $modifier={galleryModifiers[index]?.main}>
              <PimcoreImage
                image={mainImage}
                mobileImage={mainImageMobile}
                modifier="rounded-lg mb-8 tablet:mb-4"
                withAspectRatio
              />
            </MainImageWrapper>
            {(gallery || []).length > 0 && (
              <Gallery $align={inversed ? "right" : "left"} $modifier={galleryModifiers[index]?.bottom}>
                {(gallery || []).map((image, index) => (
                  <div key={`team_section_image_${index}`}>
                    <PimcoreImage image={image.image} modifier="rounded-lg" withAspectRatio />
                  </div>
                ))}
              </Gallery>
            )}
          </GalleryCell>
          <ContentCell $inversed={inversed} $modifier={contentModifier[index].modifier}>
            <Headline3 dangerouslySetInnerHTML={{ __html: name }}></Headline3>
            <Description padding={paddingDescription[index]}>
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: description }} />
            </Description>
            <ButtonWrapper>
              <ButtonLinkSolid to={WorkRoutes[link] || "#"}>Learn more</ButtonLinkSolid>
            </ButtonWrapper>
          </ContentCell>
        </Wrapper>
      </ScrollToWrapper>
    );
  }
);

const Wrapper = styled.div.attrs<WrapperProps>(({ margin }) => ({
  className: `tablet:grid tablet:grid-cols-12 tablet:gap-4 px-5 relative ${margin ? margin : "tablet:mb-32"} `,
}))<WrapperProps>`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(1, auto);
  }
`;

const Box = styled.div.attrs<CellProps>(({ $inversed }) => ({
  className: `absolute top-14 bottom-0 inset-x-0 -z-10 tablet:static  ${
    $inversed ? "tablet:col-start-5" : "tablet:col-start-1"
  } tablet:row-start-2 tablet:row-span-1 tablet:col-span-8 tablet:rounded-lg bg-gray2`,
}))<CellProps>``;

const ContentCell = styled.div.attrs<CellProps>(({ $inversed, $modifier }) => ({
  className: `${
    $inversed ? "tablet:col-start-8" : "tablet:col-start-2"
  } tablet:row-start-2 tablet:row-span-1 tablet:col-span-4 pb-16 ${
    $modifier ? $modifier : "tablet:pb-24 tablet:pt-14"
  } `,
}))<CellProps>``;

const GalleryCell = styled.div.attrs<CellProps>(({ $inversed }) => ({
  className: `${
    $inversed ? "tablet:col-start-1" : "tablet:col-start-6"
  } tablet:col-span-7 tablet:row-start-1 tablet:row-span-4 relative z-20`,
}))<CellProps>``;

const Description = styled.div.attrs<DescriptionProps>(({ padding }) => ({
  className: `mb-8 ${padding ? padding : ""}`,
}))<DescriptionProps>``;

const Gallery = styled.div.attrs<GalleryProps>(({ $align = "left", $modifier }) => ({
  className: `hidden tablet:gap-4 tablet:grid tablet:grid-flow-col ${
    $align === "right" ? " justify-end" : ""
  } ${$modifier}`,
}))<GalleryProps>``;

const MainImageWrapper = styled.div.attrs<ImageWrapperProps>(({ $modifier }) => ({
  className: `${$modifier}`,
}))<ImageWrapperProps>``;

const ScrollToWrapper = styled.div.attrs({ className: "" })``;

const ButtonWrapper = styled.div.attrs({})``;

export default TeamSection;
