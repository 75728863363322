import React from "react";
import styled from "styled-components";
import { Hero, HeroHeadline } from "../../components/hero";
import MainSection from "../../components/main-section/main-section";
import SectionGallery from "../../components/section-gallery";
import { JobsSearchTile, TileNavigation } from "../../components/tile-navigation";
import Teams from "../../sections/work/teams";
import { JobOffersCountInterface } from "../../graphql/jobs";
import { graphql, PageProps } from "gatsby";
import Layout from "../../components/layout";
import { WorkPageContentInterface } from "../../graphql/work-page";
import { transformHeroData } from "../../utilities/pimcore-data-transformer";
import Scrollspy from "../../components/scrollspy/scrollspy";

interface WorkPageDataInterface {
  pimcore: {
    content: WorkPageContentInterface;
    jobOffersCount: JobOffersCountInterface;
  };
}

const labels = [
  "Shaping Miele’s digital future",
  "eCommerce",
  "Data, Governance & Analytics",
  "IT",
  "Customer Engagement",
  "Country Success & Knowledge",
  "Human Resources",
  "Operational Excellence & Information Management",
  "Digital Marketing",
];

const WorkPage: React.FC<PageProps<WorkPageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="work"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <Hero {...transformHeroData(content)}>
          <HeroHeadline dangerouslySetInnerHTML={{ __html: content.heroTitle || "" }} />
        </Hero>
      </Header>
      <Main>
        <MainSectionWrapper>
          <MainSection
            headline={content.mainSectionHeadline}
            subHeadline={content.mainSectionSubheadline}
            content={content.mainSectionContent}
            background={content.mainSectionBackground}
            backgroundMobile={content?.mainSectionBackgroundMobile}
          >
            <SectionGallery images={content.gallery} />
          </MainSection>
        </MainSectionWrapper>
        <Teams categories={content.categories} />
        <TileNavigation top={"team"} bottom={"office"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
      </Main>
      <Scrollspy labels={labels} gap={9} />
    </Layout>
  );
};

const Main = styled.main.attrs({ className: "x-background -mt-24" })``;

const MainSectionWrapper = styled.div.attrs({ className: "overflow-hidden" })``;

const Header = styled.header.attrs({ className: "h-screen" })``;

export const query = graphql`
  query {
    pimcore {
      ...workPageData
      ...jobOffersCount
    }
  }
`;

export default WorkPage;
