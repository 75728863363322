import React from "react";
import styled from "styled-components";
import { IconChevron } from "../../components/icons";
import { PimcoreImage } from "../../components/pimcore-image";
import { ImageDataInterface } from "../../graphql/common";
import { theme } from "../../styles/styles";

interface TeamsListProps {
  teams: Array<TeamData>;
  onItemClick: (index: number) => void;
}

interface TeamData {
  name: string;
  images: Array<ImageDataInterface | undefined>;
  index: number;
}

const TeamsList: React.FC<TeamsListProps> = ({ teams, onItemClick }) => {
  return (
    <List>
      {teams.map((team, index) => (
        <ListItem key={`team_link_${index}`} onClick={onItemClick.bind(null, team.index)}>
          <PimcoreImage image={team.images[0]} mobileImage={team.images[1]} withAspectRatio />
          <NameWrapper>
            <Name dangerouslySetInnerHTML={{ __html: team.name }} />
            <ArrowWrapper>
              <IconChevron fill="stroke-yellow" />
            </ArrowWrapper>
          </NameWrapper>
        </ListItem>
      ))}
    </List>
  );
};

const List = styled.ul.attrs({
  className: "gap-2.5 tablet:gap-4 px-5 flex flex-wrap justify-center",
})``;

const ListItem = styled.li.attrs({
  className: "relative rounded-lg overflow-hidden cursor-pointer",
})`
  width: 31%;
  @media ${theme.mediaQueries.tablet} {
    width: 19%;
  }
`;

const NameWrapper = styled.div.attrs({
  className: "absolute bottom-0 left-0 right-0 text-center flex flex-col items-center py-2 tablet:py-4",
})``;

const Name = styled.div.attrs({
  className: "leading-4 tablet:leading-5 text-white text-[0.8125rem] tablet:text-paragraph font-bold mb-1 px-4",
})``;

const ArrowWrapper = styled.div.attrs({
  className: "hidden tablet:block w-2 rotate-90",
})``;

export default TeamsList;
