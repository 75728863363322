import React from "react";
import styled from "styled-components";
import { Headline2 } from "../../components/typography";
import { WorkCategoryContentInterface } from "../../graphql/work-page";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";
import TeamSection from "./team-section";
import { theme } from "../../styles/styles";
import TeamsList from "./teams-list";

interface TeamsProps {
  categories: Array<WorkCategoryContentInterface>;
}

const Teams: React.FC<TeamsProps> = ({ categories }) => {
  const sectionRefs = React.useRef<Array<HTMLDivElement | null>>([]);
  const titleRef = React.createRef<HTMLDivElement>();
  const navRef = React.createRef<HTMLDivElement>();
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const tl = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    sectionRefs.current = sectionRefs?.current?.slice(0, categories.length);
  }, [categories.length]);


  React.useEffect(() => {
    if (navRef.current && titleRef.current) {
      tl.current = onScrollAnimationDesktop(titleRef.current, [titleRef?.current, navRef.current], matchMobile, ["10rem", "18rem"]);
    }

    return () => {
      tl.current?.kill();
    };
  }, [matchMobile, titleRef, navRef]);

  const onTeamItemClick = (index: number) => {
    window.scrollTo({ top: sectionRefs?.current[index]?.offsetTop, behavior: "smooth" });
  };

  return (
    <>
      <Title ref={titleRef}>
        <strong>Our expertise</strong>
      </Title>
      <CategoriesWrapper ref={navRef}>
        <TeamsList
          teams={categories.map((team, index) => ({
            name: team.name,
            images: [team.thumbnail, team.thumbnailMobile],
            index: index,
          }))}
          onItemClick={onTeamItemClick}
        />
      </CategoriesWrapper>
      <SectionWrapper>
        {categories.map((team, index) => (
          <ScrollWrapper key={`team_section_${index}`} ref={(section) => (sectionRefs.current[index] = section)}>
            <InnerSection>
              <TeamSection index={index} {...team} />
            </InnerSection>
          </ScrollWrapper>
        ))}
      </SectionWrapper>
    </>
  );
};

const ScrollWrapper = styled.div.attrs({ className: "scrollspy relative z-10 pb-16 tablet:pb-0 department" })``;

const SectionWrapper = styled.div.attrs({ className: "" })``;

const Title = styled(Headline2).attrs({
  className: "px-5 font-bold mb-8 tablet:mb-16 tablet:text-center tablet:px-0",
})``;

const CategoriesWrapper = styled.div.attrs({
  className: "tablet:mx-24 tablet:z-40 tablet:mb-64",
})``;

const CategoriesBox = styled.div.attrs({
  className: "flex flex-wrap items-center gap-4 tablet:gap-0 px-5 tablet:px-7 tablet:py-5 tablet:bg-gray18/70 tablet:backdrop-blur-md tablet:justify-around tablet:rounded-full mb-16 tablet:mb-36",
})`
  @media ${theme.mediaQueries.tablet} {
    @supports (-moz-appearance: none) {
      background-color: rgba(50, 50, 50, 0.97);
    }
  }
`;

const CategoryLink = styled.a.attrs({
  className: "block text-white cursor-pointer hover:text-yellow hover:text-underline px-5 py-2 tablet:p-0 border-2 border-yellow rounded-full tablet:border-none",
})`
  &.activeSection {
    color: ${theme.colors.yellow};
  }
`;

const InnerSection = styled.div.attrs({
  className: "mb-12 ta tablet:mb-36 last:mb-12",
})`
  &:last-child {
    margin-bottom: 0;
  }
`;

export default Teams;
